@import url('https://fonts.googleapis.com/css2?family=Heebo');

body {
    font-family: 'Heebo', sans-serif !important;
}

#loginform {
    padding: 70px;
}

.navbarbg {
    background-color: #ffffff !important;
}

.navbar-header {
    background-color: #13396a !important;
}

.header-user-text {
    color: #15102e;
}

.header-light-dark-mode {
    margin-top: 25px;
    margin-right: 30px;
}

.light-mode-label {
    color: #238da7;
}

[data-theme="dark"] .light-mode-label {
    color: #ffffff;
}

.nav-burger {
    color: #000000;
}

[data-theme="dark"] .nav-burger {
    color: #ffffff;
}

.ReactTable.-striped .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.1) !important;
}

.dark-mode-label {
    color: #272e50;
}

[data-theme="dark"] .dark-mode-label {
    color: #238da7;
}

.header-profile-name {
    padding-top: 18px;
    color: #15102e;
}

.header-profile-arrow {
    font-size: 14px;
    margin-top: 10px;
}

.header-profile-popup-color {
    background-color: #13396a;
}

.profile-dd {
    display: none;
}

#sidebarnav {
    background-color: #13396a !important;
    padding: 0px;
    padding-top: 50px !important;
}

#sidebarnav .active {
    background: none !important;
    box-shadow: none;
    /*background-color: rgba(7, 13, 30, 0.82) !important;*/
    background-color: transparent !important;
    color: #32d6d0 !important;
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover {
    color: #32d6d0 !important;
}

#sidebarnav .sidebar-item {
    font-size: 14px;

}

#sidebarnav .sidebar-link {
    padding-top: 20px;
    padding-bottom: 20px;
}

.rectangle {
    width: 100%;
    height: 70px;
    position: relative;
    background-color: #13396a;
}

.rectangle-round-down {
    width: 100%;
    height: 70px;
    background-color: #e0ebf8;
    border-radius: 0px 0px 0px 70px;
}

[data-theme="dark"] .rectangle-round-down {
    background-color: #2c2c63 !important;

}

[dir="rtl"] .rectangle-round-down {
    border-radius: 0px 0px 70px 0px;
}

.navbarbg {
    background-color: #e0ebf8 !important;
    height: 70px;
}

[data-theme="dark"] .navbarbg {
    background-color: #2c2c63 !important;
}

.round-corner-up-bg {
    background-color: #13396a !important;
    width: 70px;
}

.round-corner-up {
    height: 70px;
    background-color: #e0ebf8;
    border-radius: 70px 0px 0px 0px;
}

[dir="rtl"] .round-corner-up {
    border-radius: 0px 70px 0px 0px !important;
}

[data-theme="light"].navbar-nav {
    background-color: #e0ebf8;
}

.footer {
    padding: 0px;
    margin: 0px;
    margin-bottom: -45px;
}

.image-modal-card {
    padding: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

[dark-theme="dark"] .cursor-pointer::after {
    background-color: #238da7 !important;
}

[data-theme="light"].custom-button {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #164669;
}

[data-theme="dark"].custom-button {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #32d6d0;
    background-color: #1b1943 !important;
}

[data-theme="dark"] .darkButton {
    color: black !important
}

[dir="rtl"] .custom-control {
    margin-left: 30px;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    font-size: 14px;
    color: #164669;
    font-weight: bold;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px;
}
.ReactTable .rt-th{
    font-size: 14px;
    color: #164669;
    font-weight: bold;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px;
}
[data-theme="dark"] .ReactTable .rt-th {
    color: #add8e6;
}

[dir="rtl"].ReactTable .rt-thead .rt-resizable-header-content {
    text-align: right;
}

[data-theme="dark"] .ReactTable .rt-thead .rt-resizable-header-content {
    color: #add8e6;
}

[data-theme="dark"] .ReactTable {
    background-color: #2c2c63;
}

.ReactTable .rt-tbody .rt-td {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
    color: #262d3c;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
}

[data-theme="dark"] .ReactTable .rt-tbody .rt-td {
    color: #ffffff;
}

/*
[data-theme="dark"] .ReactTable .rt-tbody .rt-td {
    color: #add8e6;
}
*/


.card {
    border-radius: 4px;
    border: solid 1.1px #e6e5f2;
    box-shadow: none;
}

.table-title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #272e50;
}

.device-sidebar {
    width: 400px;
    right: -400px;

}

[data-theme="dark"] .device-sidebar {
    background-color: #2c2c63 !important;

}

.device-control {
    width: 100%;
    padding-bottom: 200px;
}

.device-control .input-label {
    font-size: 18px;
    font-weight: bold;
}

.device-control .slider-number {
    font-weight: bold;
}

.device-class-card {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #262d3c;
}

.device-class-card .logo {
    width: 86px;
    margin-bottom: 20px;
}

.button-big {
    width: 180px;
    height: 45px;
    margin: 20px 0 0;
    border-radius: 4px;
    border: solid 1px #1b7389;
    color: #1b7389;
    background-color: #ffffff;
}

.device-class-card .devices-in-project {
    padding-top: 5px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: center;
    color: #262d3c;
}

[data-theme="dark"] .page-content {
    background-color: #1b1943;
}

/* [data-theme="dark"] .card-body{
    box-shadow: 0 10px 13px 7px rgba(0, 0, 0, 0.23);
    /* background-color: #2c2c63; */
*/ [data-theme="dark"] .card-body {
    border: solid 1px #2c2c63;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #32d6d0 !important
}

[data-theme="dark"] .card {
    border: 0;
}

[data-theme="dark"] .form-control {
    background-color: #2c2c63;
}

[data-theme="dark"] .bg-white {
    background-color: #2c2c63 !important;
}

[data-theme="dark"] .rectangle-round-down {
    background-color: #1b1943 !important;
}

[data-theme="dark"] .navbarbg {
    background-color: #1b1943 !important;
}

[data-theme="dark"] .round-corner-up {
    background-color: #1b1943 !important;
}

[data-theme="dark"] .d-none {
    background-color: #1b1943 !important;
}

[data-theme="dark"] .UncontrolledDropdown {
    background-color: #1b1943 !important;
}

[data-theme="dark"].navbar-nav {
    background-color: #2c2c63 !important;
}

[data-theme="dark"] .headerRightDark {
    background-color: #2c2c63 !important;
    z-index: 5;
    padding-left: 30px;
}

[data-theme="light"] .headerRightDark {
    background-color: white !important;
    z-index: 5;
    padding-left: 30px;
}

[dir="rtl"] .headerRightDark {
    background-color: #2c2c63 !important;
    z-index: 5;
    border-radius: 0px 0px 40px 0px;
    padding-right: 30px;
}

[dir="ltr"] .headerRightDark {
    background-color: #2c2c63 !important;
    z-index: 5;
    border-radius: 0px 0px 0px 40px;
    padding-right: 30px;
}

[dir="rtl"] .custom-select {
    text-align: end;
}

[data-theme="dark"] .span {
    color: #ffffff !important;
}

[data-theme="dark"] .mdi-calendar {
    color: #32d6d0 !important;
}

[data-theme="dark"].darkThemeCard {
    background-color: #2c2c63 !important;
}

[data-theme="light"].cardStyleGhostDevicesNode {
    margin: 5px 0;
    border: solid 1px #e8f0ff;
    border-radius: 10px;
    width: 180px;
    background-color: #f7f9fc;
}

[data-theme="dark"].cardStyleGhostDevicesNode {
    margin: 5px 0;
    border: solid 1px #238da7;
    border-radius: 10px;
    width: 180px;
    background-color: #2c2c63;
}

[data-theme="light"].deviceNodeCard {
    padding-top: 2px;
    border: solid 1px #e8f0ff;
    border-radius: 10px;
    width: 180px;
    margin-bottom: 14px;
}

[data-theme="dark"].deviceNodeCard {
    padding-top: 2px;
    border: solid 1px #238da7;
    border-radius: 10px;
    width: 180px;
    margin-bottom: 14px;
}

[data-theme="light"] .deviceNodeRowColor {
    background-color: #f7f9fc;
}

[data-theme="dark"] .deviceNodeRowColor {
    background-color: #2c2c63;
}

[data-theme="light"] .TreeNodeNoBorder {
    width: 180px;
    min-height: 50px;
    max-height: 60px;
    background-color: #f5f8fb;
}

[data-theme="light"] .TreeNodeBorder {
    border: solid 1px;
    border-color: #32d6d0;
    border-radius: 4px;
    width: 180px;
    min-height: 50px;
    max-height: 60px;
}

[data-theme="dark"] .TreeNodeNoBorder {
    width: 180px;
    min-height: 50px;
    max-height: 60px;
    background-color: #1b1943;
}

[data-theme="dark"] .TreeNodeBorder {
    border: solid 1px;
    border-color: #238da7;
    border-radius: 4px;
    width: 180px;
    min-height: 50px;
    max-height: 60px;
}

[data-theme="light"] .treeNodeContainer {
    border: 2px solid #32d6d0;
    border-radius: 4px
}

[data-theme="dark"] .treeNodeContainer {
    border: 2px solid #238da7;
    border-radius: 4px;
    background-color: #2c2c63;
}

[data-theme="dark"] .tooltip-inner {
    background-color: #2c2c63 !important;
    color: white !important;
}

[dir="rtl"] .customizer {
    left: -400px;
    right: auto;
}

[data-theme="light"] .headerRightDark {
    background-color: white !important;
    z-index: 5;
    padding-left: 30px;
}

.limitNameLength {
    display: inline-block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
}

[data-theme="dark"] .addProjectModalCenter {
    background-color: #2c2c63 !important
}

[data-theme="dark"] .newproject-modal .header {
    background-color: #181842 !important
}

[data-theme="dark"] tspan {
    fill: white !important;
}

[data-theme="dark"] .deviceTypeText {
    color: white
}

[data-theme="dark"] .deviceTypeTextBtn {
    background-color: #32d6d0;
    color: white
}

[data-theme="dark"] .modal-header .close {
    color: white;
}

.limitname {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.userNameLimit {
    display: block;
    width: 180px;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

[dir="ltr"].ghostDevicesCol {
    padding-left: 0px;
    padding-right: 15px;
    margin-top: -15px;
}

[dir="rtl"].ghostDevicesCol {
    padding-left: 15px;
    padding-right: 0px;
    margin-top: -15px;
}

.rt-tr {
    min-height: 30px
}

.alertsBTNgroup {
    width: 140px;
    height: 35px;
}

[data-theme="light"].maintenanceBtn {
    color: white;
    background-color: #1b7389
}

[data-theme="dark"].maintenanceBtn {
    background-color: #32d6d0 !important;
    color: #0f172e;
    border-color: #32d6d0;
}

[data-theme="light"].malfunctionsBtn {
    color: #37597a;
    background-color: #bcd0e3
}

[data-theme="dark"].malfunctionsBtn {
    color: #32d6d0 !important;
    border-color: #32d6d0;
}

.alertsCard {
    border-radius: 11.5px;
}
@media (max-width: 768px){
    .hidden-sm{
        display: none
    }

}
@media (max-width: 768px){
    .authentications{
        padding:20px
    }
}

[data-theme="light"].blackheader{
     color:#0f172e
    }
    [data-theme="dark"].blackheader{
        color:white
       }

    #addUser .ps__rail-x{
        display: none
    }

    .addUserBtn{
        width: 300px;
    }
    
    [data-theme="light"] .addUserBtn{
        background-color:#164669;
        color:white;
    }
    [data-theme="light"] .addUserBtn:disabled{
        background-color:#b5b6c6;
        color:white;

    }
    [data-theme="dark"] .addUserBtn{
        background-color: #32d6d0;
        color:#0f172e
    }